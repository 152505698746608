import React from 'react';
import { Icon } from '@angellist/adapt';
import { alphaNumericSortFunc } from '.';
import { Money } from '../../graphql';
import {
  formatMoneyObject,
  formatNumber,
  formatNumberText,
  formatTitle,
  formatBoolean,
} from '../helpers/utils';
import { formatDate, formatDateTime, formatDateTimeHuman } from './date';
import StatusTag from '../components/common/StatusTag';

export const csvOnlyColumn = (text: any, dataField: string, options = {}) => ({
  dataField,
  text,
  isDummyField: true,
  csvExport: true,
  csvFormatter: (value: any) => value || '',
  hidden: true,
  ...options,
});

export const filterOnlyColumn = (dataField: string, options = {}) => ({
  dataField,
  text: '',
  isDummyField: true,
  csvExport: false,
  hidden: true,
  ...options,
});

enum ALIGIN {
  RIGHT = 'right',
}

export const RIGHT_ALIGN_COLUMN_ATTRIBUTES = {
  align: ALIGIN.RIGHT,
  headerAlign: ALIGIN.RIGHT,
  classes: 'right-align-column',
  headerClasses: 'right-align-column',
};

const defaultMoneyColumnConfig = {
  sort: true,
  headerStyle: { minWidth: 120 },
  ...RIGHT_ALIGN_COLUMN_ATTRIBUTES,
};

export const COLUMN_ATTRIBUTES = {
  ACTION: {
    dataField: '',
    text: 'Actions',
    isDummyField: true,
    classes: 'action-dropdown-container',
    align: 'left',
    csvExport: false,
  },
  ADAPT_ACTION: {
    dataField: '',
    text: '',
    isDummyField: true,
    csvExport: false,
    classes: 'adapt-cell-dropdown-container',
  },
  NUMBER: {
    formatter: (number?: number) => formatNumber(number),
    csvFormatter: (number?: number) => formatNumber(number),
    sort: true,
    headerStyle: { minWidth: 120 },
    sortFunc: alphaNumericSortFunc,
    searchValue: (number?: number) => `${number}_or_${formatNumber(number)}`,
  },
  NUMBER_TEXT: {
    formatter: (number?: number) => formatNumberText(number),
    csvFormatter: (number?: number) => formatNumberText(number, '–'),
    sort: true,
    headerStyle: { minWidth: 120 },
    searchValue: (number?: number) => `${number}_or_${formatNumber(number)}`,
  },
  DATE: {
    formatter: (date?: string) => formatDate(date, '–'),
    csvFormatter: (date?: string) => formatDate(date, '–'),
    sort: true,
    headerStyle: { minWidth: 125 },
  },
  DATETIME: {
    formatter: (date?: string) => formatDateTimeHuman(date, '–'),
    searchValue: (date?: string) => formatDateTimeHuman(date, '–'),
    csvFormatter: (date?: string) => formatDateTime(date, '–'),
    sort: true,
    headerStyle: { minWidth: 130 },
  },
  TEXT: {
    formatter: (text?: string) => text || '–',
    csvFormatter: (text?: string) => text || '–',
    sort: true,
    headerStyle: { minWidth: 110 },
  },
  TITLE: {
    formatter: (text?: string) => formatTitle(text),
    csvFormatter: (text?: string) => formatTitle(text),
    searchValue: (text?: string) => formatTitle(text),
    filterValue: (text?: string) => text,
    sort: true,
    headerStyle: { minWidth: 120 },
  },
  MONEY: {
    formatter: (moneyObj: Money) => formatMoneyObject(moneyObj),
    csvFormatter: (moneyObj: Money) => formatMoneyObject(moneyObj),
    sortValue: (moneyObj: Money) => (moneyObj ? Number(moneyObj.dollars) : 0),
    searchValue: (moneyObj: Money) => (moneyObj ? Number(moneyObj.dollars) : 0),
    ...defaultMoneyColumnConfig,
  },
  BETTER_MONEY: {
    formatter: (moneyObj: Money) => (moneyObj ? moneyObj.betterFormat : '—'),
    csvFormatter: (moneyObj: Money) => (moneyObj ? moneyObj.betterFormat : '—'),
    sortValue: (moneyObj: Money) =>
      moneyObj ? Number(moneyObj.fractional) : 0,
    searchValue: (moneyObj: Money) =>
      moneyObj ? Number(moneyObj.fractional) : 0,
    ...defaultMoneyColumnConfig,
  },
  FORMAT_MONEY: {
    formatter: (moneyObj: Money) => (moneyObj ? moneyObj.format : '—'),
    csvFormatter: (moneyObj: Money) => (moneyObj ? moneyObj.format : '—'),
    sortValue: (moneyObj: Money) =>
      moneyObj ? Number(moneyObj.fractional) : 0,
    searchValue: (moneyObj: Money) =>
      moneyObj ? Number(moneyObj.fractional) : 0,
    ...defaultMoneyColumnConfig,
  },
  STATUS_TAG: {
    formatter: (status?: string) => <StatusTag status={status} />,
    csvFormatter: (status?: string) => formatTitle(status),
    searchValue: (status?: string) => formatTitle(status),
    filterValue: (status?: string) => status,
    sort: true,
    headerStyle: { minWidth: 110 },
  },
  BOOLEAN: {
    formatter: (boolean: string | boolean) => formatBoolean(boolean),
    csvFormatter: (boolean: string | boolean) => formatBoolean(boolean),
    sort: true,
    headerStyle: { minWidth: 120 },
  },
  BOOLEAN_Y_N: {
    formatter: (boolean: boolean) => {
      if (boolean === null) return '—';
      if (boolean === true) return <Icon named="check" />;
      if (boolean === false) return <Icon named="x" />;
      return '';
    },
    csvFormatter: (boolean: boolean) => boolean,
    sort: true,
    headerStyle: { minWidth: 80 },
    classes: 'text-center',
  },
};
